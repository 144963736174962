<template>
  <MDBContainer>
    <div class="timeline">
      <div class="roadmap left-align">
        <div class="roadmap-content">
          <h2>Q4 - 2021</h2>
          <ul class="list-group">
            <li class="list-group-item">
              Core team assembled, R&amp;D center established.
            </li>
        
            <li class="list-group-item">
              Initial White paper completed and circulated to potential Tamil
              patriotics.
            </li>


            <li class="list-group-item">
              TAMIL Crypto social media presents start.
            </li>
            <li class="list-group-item">Reward Program for community</li>
          </ul>
        </div>
      </div>
      <div class="roadmap right-align">
        <div class="roadmap-content">
          <h2>Q1 - 2022</h2>
          <ul class="list-group">
            <!-- <li class="list-group-item">White Paper Released to Public.</li> -->
            <li class="list-group-item">Website Goes Live</li>
            <li class="list-group-item">TAMIL Crypto Creation</li>
            <li class="list-group-item">Reward Program for community</li>
          </ul>
        </div>
      </div>
      <div class="roadmap left-align">
        <div class="roadmap-content">
          <h2>Q3 - 2022</h2>
          <ul class="list-group">
            <li class="list-group-item">
              Tamil Digital Assets Decentralization - Phase 1
            </li>
            <li class="list-group-item">Reasearch and Development Actiivty</li>
          </ul>
        </div>
      </div>
      <div class="roadmap right-align">
        <div class="roadmap-content">
          <h2>Q4 - 2022</h2>
          <ul class="list-group">
            <li class="list-group-item">
              Tamil Digital Assets Decentralization - Phase 2
            </li>
            <li class="list-group-item">Desktop Node Runner Development R&D</li>
            <li class="list-group-item">Reward Program Defined </li>

          </ul>
        </div>
      </div>
      <div class="roadmap left-align">
        <div class="roadmap-content">
          <h2>Q2 - 2023</h2>
          <ul class="list-group">
            <li class="list-group-item">
              Tamil Digital Assets Decentralization - Phase 3
            </li>
            <li class="list-group-item">Release Desktop Node Runner for WIndows</li>
            <li class="list-group-item">Wallet Restructure</li>
          </ul>
        </div>
      </div>
      <div class="roadmap right-align">
        <div class="roadmap-content">
          <h2>Q4 - 2023</h2>
          <ul class="list-group">
            <li class="list-group-item">
              Partner Enrollment
            </li>
            <li class="list-group-item">Mobile Apps - Feature Development</li>
 
          </ul>
        </div>
      </div>

      <div class="roadmap left-align">
        <div class="roadmap-content">
          <h2>Q1 - 2024</h2>
          <ul class="list-group">
            <li class="list-group-item">
              Tamil Digital Assets Decentralization - Phase 2
            </li>
            <li class="list-group-item">Merchant enrolling</li>
            <li class="list-group-item">Dex Tamil Archive</li>
          </ul>
        </div>
      </div>

      <div class="roadmap right-align">
        <div class="roadmap-content">
          <h2>Q2 - 2024</h2>
          <ul class="list-group">
            <li class="list-group-item">
              Tamil Crypto - Connect
            </li>
            <li class="list-group-item">Invitation Module - Release</li>
            <li class="list-group-item">Mobile App Release - Android</li>

          </ul>
        </div>
      </div>

      <div class="roadmap left-align">
        <div class="roadmap-content">
          <h2>Q3 - 2024</h2>
          <ul class="list-group">
            <li class="list-group-item">
              Tamil Crypto - Connect
            </li>
            <li class="list-group-item">Voting Mechanism</li>
          </ul>
        </div>
      </div>

      <div class="roadmap left-align">
        <div class="roadmap-content">
          <h2>Q4 - 2024</h2>
          <ul class="list-group">
            <li class="list-group-item">
              Tamil Crypto - Marketplace
            </li>
            <li class="list-group-item">Peer-to-Peer Marketplace</li>
            <li class="list-group-item">Targeting 1,000 Partnerships Milestone</li>
          </ul>
        </div>
      </div>

    </div>
  </MDBContainer>
</template>

<script>
import { MDBContainer } from "mdb-vue-ui-kit";

export default {
  name: "RoadMap",
  components: {
    MDBContainer,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
* {
  box-sizing: border-box;
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #ffa800;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.roadmap {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.roadmap::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: #ffa800;
  border: 4px solid #ffff00;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left-align {
  left: -4px;
}

/* Place the container to the right */
.right-align {
  left: 50%;
  margin-left: 4px;
}

/* Add arrows to the left container (pointing right) */
.left-align::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right-align::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right-align::after {
  left: -16px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .roadmap {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .roadmap::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left-align::after,
  .right-align::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right-align {
    left: 0%;
  }
}
</style>
